import React, { useEffect } from 'react';
import { create, Tooltip } from '@amcharts/amcharts4/core';
import { XYChart, CategoryAxis, ValueAxis, ColumnSeries, Legend } from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const DashboardLast30DaysSubRenewalBarChart = ({ data }) => {
  useEffect(() => {
    // Apply the theme
    // am4core.useTheme(am4themes_animated);

    // Create chart instance
    let chart = create('chartdiv', XYChart);

    // Set chart data
    chart.data = data;

    // Create axes
    let categoryAxis = chart.xAxes.push(new CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.grid.template.location = 0;
    // Set the minimum distance between grid lines
    categoryAxis.renderer.minGridDistance = 30; // Adjust this value as needed
    // Rotate labels by 90 degrees
    categoryAxis.renderer.labels.template.rotation = 270;

    let valueAxis = chart.yAxes.push(new ValueAxis());

    // Create series
    let series1 = chart.series.push(new ColumnSeries());
    series1.dataFields.valueY = 'subs_revenue';
    series1.dataFields.categoryX = 'date';
    series1.name = 'Subscription Revenue';
    series1.columns.template.tooltipText = 'Subcription: [bold]{valueY}[/]'; // Update the tooltip configuration


    let series2 = chart.series.push(new ColumnSeries());
    series2.dataFields.valueY = 'renewal_revenue';
    series2.dataFields.categoryX = 'date';
    series2.name = 'Renewal Revenue';
    series2.columns.template.tooltipText = 'Renewal: [bold]{renewal_revenue}[/]';

    // Add legend
    chart.legend = new Legend();

    // Add tooltip
    chart.cursor = new Tooltip();

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [data]);

  return <div id="chartdiv" style={{ width: '100%', height: '300px' }}></div>;

  
};

export default DashboardLast30DaysSubRenewalBarChart;

import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux'
import { postCall } from '../api/apiService.js'
import { DATE_WISE_REVENUE_REPORT_DATA, DATE_WISE_REVENUE_REPORT_FILTER_DATA } from '../api/apiPath.js'
import { toast } from 'react-toastify'
import { SET_BREADCRUMB_DATA, SET_USER_DATA } from '../redux/action.js'
import { permissionsResets, createAuditLog, userAgent, badge_colors, numberFormat, calculateTableTotalForSpecificColumn } from '../components/Helpers/CommonHelpers.js'
import Select from 'react-select'

import moment from 'moment';
import Submitbutton from '../components/Buttons/Submitbutton.js'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function DateWiseRevenueReport(props) {

    const breadcrumb = {
        pageTitle: 'Date Wise Revenue Report',
        currentPath: '/date-wise-revenue-report',
        layers: [
            {
                title: 'Home',
                link: '/'
            },
            {
                title: 'Report'
            },
            {
                title: 'Date Wise Revenue Report',
                default: 1
            }
        ]
    }

    const formInitial = {
        filter: {
            package_id: null,
            package_selected: null,
            start_date_init: null,
            start_date: null,
            end_date_init: null,
            end_date: null,
        },
    }
    const [formData, setFormData] = useState(formInitial)

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }


    const [reportData, setReportData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [noDataFound, setNoDataFound] = useState(false)

    const getReportData = async (e) => {
        setNoDataFound(false)

        setIsLoading(true)
        setReportData([])
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        let request = { ...formData?.filter }
        var response = await postCall(DATE_WISE_REVENUE_REPORT_DATA, { ...request }, props.user.access_token)
        if (response?.code === 200) {
            setReportData(response?.data?.data);
            setIsLoading(false)
            if (response?.data?.data?.length == 0) { setNoDataFound(true); }
            else setNoDataFound(false)
        } else {
            toast.error(response?.message?.[0])
            setReportData([]);
            setIsLoading(false)
            setNoDataFound(true);
        }
    }


    useEffect(() => {
        getFilterData()
        permissionsResets(props)
        createAuditLog(props, { log_type_id: 3, hit_map: 'page', page: breadcrumb?.pageTitle, page_url: window.location.href, user_agent: userAgent })
        props.setPageBreadcrumb(breadcrumb)

    }, [])

    // userList select process
    const [filterData, setFilterData] = useState({})
    const getFilterData = async () => {
        let response = await postCall(DATE_WISE_REVENUE_REPORT_FILTER_DATA, {}, props.user.access_token)
        if (response?.code === 200) {
            setFilterData(response?.data?.filter)
        } else {
            setFilterData({})
        }
    }

    const clear = () => {
        setFormData(formInitial)
    }

    const reset = () => {
        setFormData(formInitial)
    }

    return (
        <Fragment>

            <div className="card col-12">

                <div className="card-header pt-5">
                    <form className="w-100" onSubmit={getReportData} >
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                    <label htmlFor="package" className="form-label">Package</label>
                                    <Select options={filterData?.packages}
                                        value={formData?.filter?.package_selected}
                                        onChange={(value) => setFormData((prev) => ({
                                            ...prev,
                                            filter: {
                                                ...prev.filter,
                                                package_id: value?.value,
                                                package_selected: value
                                            }
                                        }))}
                                        isClearable
                                        placeholder="Select Package"
                                    />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <div className="input-group">
                                    <label htmlFor="start_date" className="form-label">Start Date</label>
                                    <DatePicker
                                        id="start_date"
                                        selected={formData?.filter?.start_date_init}
                                        onChange={(date) => setFormData((prev) => ({
                                            ...prev,
                                            filter: {
                                                ...prev.filter,
                                                start_date_init: date,
                                                start_date: moment(date).format('yy-MM-DD')
                                            }
                                        }))}
                                        dateFormat="yyyy-MM-dd"
                                        required
                                        className="form-control"
                                        placeholderText="Start Date"
                                    />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <div className="input-group">
                                    <label htmlFor="end_date" className="form-label">End Date</label>
                                    <DatePicker
                                        id="end_date"
                                        selected={formData?.filter?.end_date_init}
                                        onChange={(date) => setFormData((prev) => ({
                                            ...prev,
                                            filter: {
                                                ...prev.filter,
                                                end_date_init: date,
                                                end_date: moment(date).format('yy-MM-DD')
                                            }
                                        }))}
                                        dateFormat="yyyy-MM-dd"
                                        required
                                        className="form-control"
                                        placeholderText="End Date"
                                    />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex align-items-center">
                                <div>
                                    <Submitbutton />
                                    <button type="button" className="btn btn-sm btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger" onClick={reset}>Reset</button>
                                </div>
                            </div>

                        </div>

                    </form>

                </div>

                <div className="card-block  py-5 px-2">

                    {
                        isLoading || noDataFound ?
                            <div className="row col-12" style={{ marginTop: "100px" }}>
                                {
                                    isLoading ?
                                        <div className="spinner-border text-primary mx-auto " style={{ width: "70px", height: "70px" }} alt="Loading..." ></div>
                                        : null
                                }
                                {
                                    noDataFound ?
                                        <div className="mx-auto">
                                            <label className="badge badge-inverse-warning label-lg label" style={{ fontSize: "18px" }} >No Data Found!</label>
                                        </div>
                                        : null
                                }
                            </div> : null
                    }


                    {
                        reportData?.length > 0 ?
                            <Fragment>

                                <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
                                    <thead>
                                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                            <th>Serial</th>
                                            <th>Package</th>
                                            <th>Price</th>
                                            <th>Receive Date</th>
                                            <th>Total Hit</th>
                                            <th>Gross Revenue(BDT)</th>
                                            <th>Wintel-Client Revenue(BDT)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th colSpan={4}><strong>Total</strong></th>
                                            <th><strong>{numberFormat(calculateTableTotalForSpecificColumn(reportData, 'No Of Hit'))}</strong></th>
                                            <th><strong>{numberFormat(calculateTableTotalForSpecificColumn(reportData, 'Gross Revenue(BDT)'))}</strong></th>
                                            <th><strong>{numberFormat(calculateTableTotalForSpecificColumn(reportData, 'Wintel-Client Revenue(BDT)'))}</strong></th>
                                        </tr>
                                        {
                                            reportData?.map((row, i) => {
                                                return (
                                                    <tr key={'row-summary-data-' + i}>
                                                        <td>{i + 1}</td>
                                                        <td>{row?.SubscriptionName}</td>
                                                        <td>{row?.price}</td>
                                                        <td>{row?.['Receive Time']}</td>
                                                        <td>{numberFormat(row?.['No Of Hit'])}</td>
                                                        <td>{numberFormat(row?.['Gross Revenue(BDT)'])}</td>
                                                        <td>{numberFormat(row?.['Wintel-Client Revenue(BDT)'])}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr>
                                            <th colSpan={4}><strong>Total</strong></th>
                                            <th><strong>{numberFormat(calculateTableTotalForSpecificColumn(reportData, 'No Of Hit'))}</strong></th>
                                            <th><strong>{numberFormat(calculateTableTotalForSpecificColumn(reportData, 'Gross Revenue(BDT)'))}</strong></th>
                                            <th><strong>{numberFormat(calculateTableTotalForSpecificColumn(reportData, 'Wintel-Client Revenue(BDT)'))}</strong></th>
                                        </tr>
                                    </tbody>
                                </table>


                            </Fragment>
                            : null
                    }
                </div>
            </div>



        </Fragment>
    )
}



const mapStateToProps = (state) => ({
    user: state.user,
    roles: state.roles,
    permissions: state.permissions
});

const mapDispatchToProps = (dispatch) => ({
    setPageBreadcrumb: (data) => dispatch(SET_BREADCRUMB_DATA(data)),
    me: (data) => dispatch(SET_USER_DATA(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(DateWiseRevenueReport));


import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux'
import { SET_BREADCRUMB_DATA, SET_USER_DATA } from '../redux/action'
import { permissionsResets, createAuditLog, userAgent, numberFormat, calculateTableTotalForSpecificColumn } from '../components/Helpers/CommonHelpers'
import { toast } from 'react-toastify'
import { DASHBOARD_DATA } from '../api/apiPath'
import { postCall } from '../api/apiService'
import DashboardLast30DaysSubRenewalBarChart from '../components/Chart/Dashboard/DashboardLast30DaysSubRenewalBarChart'
import PieChartComponentDashboardPackageWise from '../components/Chart/Dashboard/PieChartComponentDashboardPackageWise';

function Dashboard(props) {
    const breadcrumb = {
        pageTitle: 'Dashboard',
        currentPath: '/',
        layers: [
            {
                title: 'Home',
                link: '/',
            }
        ],

    }
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props?.user?.force_password != 0) {
            props.history.push('/profile')
        }
        permissionsResets(props)
        props.setPageBreadcrumb(breadcrumb)
        dashboard_data_process()
        createAuditLog(props, { hit_map: 'page', page: breadcrumb?.pageTitle, page_url: window.location.href, user_agent: userAgent })
    }, [])

    const dashboard_data_process = async (e) => {
        var response = await postCall(DASHBOARD_DATA, null, props.user.access_token)
        if (response?.code === 200) {
            setData(response?.data)
        } else {
            toast.error(response?.message?.[0])
            setData(null)
        }
    }

    const badgeGetter = (i) => {
        if ([1, 2, 3].includes(i)) {
            return <img alt="Rank" src={`assets/media/rank/rank-${i}.png`} className="h-lg-25px h-40px" />;
        } else {
            return <span className="badge badge-circle badge-light h-30px w-30px">{i + 1}</span>;
        }
    }

    

    return (
        <React.Fragment>

            <div className="card col-12 border-0">

                <div className="card-block  py-0 px-0 mx-0 my-0">

                    <div className='row'>
                        <div className="card card-flush h-md-50 mb-5 mb-xl-10 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-12 mx-0 border-0">
                            <div className="card">
                                <div className="card-header pt-5 px-4">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="fs-2hx fw-bolder text-dark me-2 lh-1">{numberFormat(data?.summary?.daily_package_gross_rev || 0)}</span>
                                        <span className="text-gray-400 pt-2 fw-bold fs-6">Daily Revenue</span>
                                    </h3>
                                    <div className="card-toolbar">
                                        <span className="badge py-3 px-4 fs-7 badge-light-primary" data-kt-timeline-widget-1="tab" data-bs-toggle="tab" href="#kt_timeline_widget_1_tab_day">Daily</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-flush h-md-50 mb-5 mb-xl-10 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-12 mx-0 border-0">
                            <div className="card">
                                <div className="card-header pt-5 px-4">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="fs-2hx fw-bolder text-dark me-2 lh-1">{numberFormat(data?.summary?.weekly_package_gross_rev || 0)}</span>
                                        <span className="text-gray-400 pt-2 fw-bold fs-6">Weekly Revenue</span>
                                    </h3>
                                    <div className="card-toolbar">
                                        <span className="badge py-3 px-4 fs-7 badge-light-primary" data-kt-timeline-widget-1="tab" data-bs-toggle="tab" href="#kt_timeline_widget_1_tab_day">Weekly</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-flush h-md-50 mb-5 mb-xl-10 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-12 mx-0 border-0">
                            <div className="card">
                                <div className="card-header pt-5 px-4">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="fs-2hx fw-bolder text-dark me-2 lh-1">{numberFormat(data?.summary?.monthly_package_gross_rev || 0)}</span>
                                        <span className="text-gray-400 pt-2 fw-bold fs-6">Monthly Revenue</span>
                                    </h3>
                                    <div className="card-toolbar">
                                        <span className="badge py-3 px-4 fs-7 badge-light-primary" data-kt-timeline-widget-1="tab" data-bs-toggle="tab" href="#kt_timeline_widget_1_tab_day">Monthly</span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="card card-flush h-md-50 mb-5 mb-xl-10 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-12 mx-0 border-0">
                            <div className="card">
                                <div className="card-header pt-5 px-4">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="fs-2hx fw-bolder text-dark me-2 lh-1">{numberFormat(data?.summary?.total_all_packages_gross_rev || 0)}</span>
                                        <span className="text-gray-400 pt-2 fw-bold fs-6">Total Revenue</span>
                                    </h3>
                                    <div className="card-toolbar">
                                        <span className="badge py-3 px-4 fs-7 badge-light-primary" data-kt-timeline-widget-1="tab" data-bs-toggle="tab" href="#kt_timeline_widget_1_tab_day">Total</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="card col-12">
                <div className="card-header pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-dark"><i className="la la-bar-chart"></i> Last 30 Days Subscription & Renewal Revenue Data</span>
                    </h3>
                </div>
                <div className="card-body pt-0 px-0">

                    <div className='row'>
                        <DashboardLast30DaysSubRenewalBarChart data={data?.last_30_days_sub_renewal_rev_data} />
                    </div>
                </div>
            </div>

            <div className="card col-12 border-0 mt-8">
                <div className="card-block  py-0 px-0 mx-0 my-0">
                    <div className='row'>
                        <div className="card card-flush h-md-50 mb-5 mb-xl-10  mb-md-5 mb-xl-10 col-md-6 col-lg-6 col-xl-6 col-xxl-6 border-0">
                            <div className='card'>
                                <div className="card-header pt-5">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bolder text-dark"><i className="la la-pie-chart"></i> Package Wise Revenue</span>
                                    </h3>
                                </div>
                                <div className="card-body d-flex align-items-end px-0 pb-0">
                                    <PieChartComponentDashboardPackageWise data={data?.package_wise_revenue_chart} />
                                </div>
                            </div>
                        </div>

                        <div className="card card-flush h-md-50 mb-5 mb-xl-10  mb-md-5 mb-xl-10 col-md-6 col-lg-6 col-xl-6 col-xxl-6 border-0">
                            <div className='card'>
                                <div className="card-header pt-5">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bolder text-dark"><i className="la la-table"></i> Summary</span>
                                    </h3>
                                </div>
                                <div className="card-body d-flex align-items-end px-0 pb-0 mx-2">
                                    {
                                        data?.total_revenue_data?.length > 0 ?
                                            <Fragment>

                                                <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
                                                    <thead>
                                                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                                            <th>Package</th>
                                                            <th>Price</th>
                                                            <th>Total Hit</th>
                                                            <th>Gross Revenue(BDT)</th>
                                                            <th>Wintel-Client Revenue(BDT)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data?.total_revenue_data?.map((row, i) => {
                                                                return (
                                                                    <tr key={'row-summary-data-' + i}>
                                                                        <td>{row?.SubscriptionName}</td>
                                                                        <td>{row?.price}</td>
                                                                        <td>{numberFormat(row?.['No Of Hit'])}</td>
                                                                        <td>{numberFormat(row?.['Gross Revenue(BDT)'])}</td>
                                                                        <td>{numberFormat(row?.['Wintel-Client Revenue(BDT)'])}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    <tr>
                                                        <th colSpan={2}><strong>Total</strong></th>
                                                        <th><strong>{numberFormat(calculateTableTotalForSpecificColumn(data?.total_revenue_data, 'No Of Hit'))}</strong></th>
                                                        <th><strong>{numberFormat(calculateTableTotalForSpecificColumn(data?.total_revenue_data, 'Gross Revenue(BDT)'))}</strong></th>
                                                        <th><strong>{numberFormat(calculateTableTotalForSpecificColumn(data?.total_revenue_data, 'Wintel-Client Revenue(BDT)'))}</strong></th>
                                                    </tr>
                                                    </tbody>
                                                </table>


                                            </Fragment>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>

                     
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}


const mapStateToProps = (state) => ({
    user: state.user,
    roles: state.roles,
    permissions: state.permissions
});

const mapDispatchToProps = (dispatch) => ({
    setPageBreadcrumb: (data) => dispatch(SET_BREADCRUMB_DATA(data)),
    me: (data) => dispatch(SET_USER_DATA(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
